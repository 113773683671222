import { FormattedMessage } from 'react-intl';
import { BannerMessage, Strong, Text, twMerge } from '@pledge-earth/product-language';
import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

const strong = (chunks: ReactNode) => <Strong>{chunks}</Strong>;

export function TestModeBar({ className, isPublicPage = false }: { className?: string; isPublicPage?: boolean }) {
  const location = useLocation();

  const isOffsettingPath =
    location.pathname.startsWith('/test/offsetting') ||
    location.pathname.startsWith('/test/orders') ||
    location.pathname.startsWith('/test/impact-links');

  let formattedMessageId: 'testModeBar.message' | 'testModeBar.message.public' | 'testModeBar.message.offsetting';
  if (isPublicPage) {
    formattedMessageId = 'testModeBar.message.public';
  } else if (isOffsettingPath) {
    formattedMessageId = 'testModeBar.message.offsetting';
  } else {
    formattedMessageId = 'testModeBar.message';
  }

  return (
    <BannerMessage
      variant="warning"
      className={twMerge('TestModeBar justify-center rounded-none p-2 print:rounded-sm', className)}
    >
      <Text>
        <FormattedMessage id={formattedMessageId} values={{ b: strong }} />
      </Text>
    </BannerMessage>
  );
}
